
@import url('https://fonts.googleapis.com/css2?family=Red+Rose:wght@300..700&display=swap');

/*==================== General Styles ====================*/
body {
  font-family: 'Arial', sans-serif;
  color: #333;
  margin: 0;
  padding: 0;
  background-color: #dce0df !important;
  line-height: 1.6;
}

/*==================== Navbar Styles ====================*/
.navbar {
  background-color: rgba(10, 37, 21, 0.7) !important; /* Dark green with transparency */
  padding: 1rem 2rem;
  position: fixed;
  width: 100%;
  z-index: 1000;
}

/* 
.navbar-brand img {
  max-height: 40px;
} */

.navbar-nav .nav-link {
  color: #fff !important; /* White links */
  font-size: 1rem;
  margin-left: 1rem;
  transition: color 0.3s ease; /* Smooth transition */
}

.navbar-nav .nav-link:hover {
  color: #b5b5b5 !important; /* Change to black on hover */
}

.navbar-toggler {
  border-color: rgba(255, 255, 255, 0.5);
}

/* .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,0.8)' stroke-width='2' d='M4 7h22M4 15h22M4 23h22' /%3E%3C/svg%3E");
} */


/*==================== Hero Section Styles ====================*/
 /* .hero-section {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.carousel-image {
  height: 100vh;
  background-size: cover;
  background-position: center;
  filter: brightness(0.7);
}

.carousel-caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.carousel-caption h1 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.carousel-caption p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.carousel-caption .btn {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
}  */

/*==================== Watch List Styles ====================*/
/* .watch-list {
  padding: 4rem 0;
  background-color: #fff;
}

.watch-list .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
} */

/*==================== Watch Card Styles ====================*/


/*==================== Watch Details Styles ====================*/
/* .watch-details-container {
  padding: 6rem 0 4rem 0;
}

.watch-details-container h1 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.watch-details-container img {
  width: 100%;
  max-width: 500px;
  border-radius: 15px;
  margin-bottom: 2rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.watch-details-container p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #555;
} */

/*==================== Footer Styles ====================*/
footer {
  background-color: #333;
  color: #fff;
  padding: 2rem 0;
  text-align: center;
}

footer p {
  margin: 0;
  font-size: 1rem;
}
